import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Content, { HTMLContent } from '../components/Content';
import Seo from '../components/seo';
import PageHeader from '../components/PageHeader';
import Img from '../components/Image';

export const StandardPageTemplate = ({
  title,
  subtitle,
  content,
  contentComponent,
  image
}) => {
  const PageContent = contentComponent || Content;

  return (
    <div className="columns is-centered">
      <div className="column text">
        <PageHeader title={title} subtitle={subtitle} />
        {image ? (
          <Img
            alt={title}
            className="full-width-image"
            node={image}
            style={{ marginBottom: '35px' }}
          />
        ) : (
          <hr />
        )}
        <PageContent className="content" content={content} />
      </div>
    </div>
  );
};

function StandardPage({ data }) {
  const { markdownRemark: post } = data;
  const image = post.frontmatter.image || '';

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        url={post.fields.slug}
      />
      <StandardPageTemplate
        subtitle={post.frontmatter.description}
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        image={image}
      />
    </Layout>
  );
}

export default StandardPage;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      html
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
          publicURL
        }
      }
    }
  }
`;
